import React, { Component } from 'react';
import * as Colyseus from "colyseus.js";
import { Route } from 'react-router';
import QRCode from 'qrcode.react';
import Lottie from 'react-lottie';
import { Howl, Howler } from "howler";
import Confetti from 'react-confetti';
import * as Sentry from "@sentry/react";
import LoggingService from "services/logging";

import Loading from "components/Loading";
import Player from "components/Player";
import Menu from "components/Menu";
import Stage from "components/Stage";
import Tutorial from "components/Tutorial";

import getAvatarById from "constants/avatars";

import logo from "images/Host/Logo.png";
import mutedIcon from "images/Host/scrawl_muted.png";
import unmutedIcon from "images/Host/scrawl_unmuted.png";
import fullscreenIcon from "images/Host/scrawl_fullscreen.png";
import helpIcon from "images/Host/scrawl_help.png";

import curtainImg from "images/Host/Curtain.png";
import easelFront from "images/Host/Easel Front.png";
import easelBack from "images/Host/Easel Back.png";
import stool from "images/Host/Stool.png";
import stageImg from "images/Host/Stage.png";
import resultsStage from "images/Host/ResultsStage.png";
import easelMiddle from "images/Host/Result Easel Middle.png";
import Badge1st from "images/Host/1stRosette.png";
import Badge2nd from "images/Host/2ndRosette.png";
import Badge3rd from "images/Host/3rdRosette.png";
import ButtonImg from "images/Host/ButtonImg.png";


import timerTurning from "animations/timerTurning.js";
import timerEnd from "animations/timerEnd.js";

import BGMusic from "audio/DrawWithDaveMusic.wav";
import CorrectGuessSFX from "audio/CorrectGuess.wav";
import DaveImageRevealSFX from "audio/DaveImageReveal.wav";
import EndingSFX from "audio/Ending.wav";
import PointSoundSFX from "audio/PointSound.wav";
import RoundCompleteSFX from "audio/RoundComplete.mp3";
import WrongGuessSFX from "audio/WrongGuess.wav";
import Timer5SecondsClockSFX from "audio/timer-5-seconds-clock.mp3";
import DrumRollSFX from "audio/drum-roll.wav";

import "animate.css";
import styles from 'components/HostStyles.module.scss';
import ErrorModal from './Utility/ErrorModal';


const fullscreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled ? true : false;

var audio = {
    BgMusic: {
        import: BGMusic,
        loaded: null,
        volume: 0.15,
        loop: true,
    },
    CorrectGuess: {
        import: CorrectGuessSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    DaveImageReveal: {
        import: DaveImageRevealSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Ending: {
        import: EndingSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PointSound: {
        import: PointSoundSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    RoundComplete: {
        import: RoundCompleteSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    WrongGuess: {
        import: WrongGuessSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Timer5SecondsClock: {
        import: Timer5SecondsClockSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    DrumRoll: {
        import: DrumRollSFX,
        loaded: null,
        volume: 1,
        loop: false,
    }
};

const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    Idle: "idle",
    DaveDrawing: "dave_drawing",
    FinalDrawing: "final_drawing",
    EarlyGuessing: "early_guessing",
    FinalGuessing: "final_guessing",
    DaveChoosing: "dave_choosing",
    GameOver: "game_over",
    EndGame: "end_game",
};

const gameId = "draw_with";


export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            room: null,
            myId: null,
            roomState: null,
            redirect: null,
            reconnectTries: 0,
            connected: false,
            //connected: true,
            reconnectionToken: "",
            logStreamId: "",

            muted: false,
            menuOpen: false,
            tickedSkipTutorial: false,
            gameBegun: false,

            players: [],
            //players: [
            //    {
            //        name: "BOB",
            //        avatar: 1,
            //        drawWithData: {
            //            currentGuess: "",
            //            score: 6,
            //        }
            //    },
            //    {
            //        name: "BOB",
            //        avatar: 6,
            //        drawWithData: {
            //            currentGuess: "",
            //            score: 3,
            //        }
            //    },
            //    {
            //        name: "BOB",
            //        avatar: 23,
            //        drawWithData: {
            //            currentGuess: "",
            //            score: 1,
            //        }
            //    }
            //],
            playersRequired: 3,
            showPlayers: true,
            gameState: GameStates.Loading,

            doingTutorial: false,
            showTutorial: false,

            skipTutorialCount: 0,
            timerOptions: timerTurning,
            timerText: 0,
            showTimer: false,

            showStartButtons: true,
            //showStartButtons: false,

            curtainsClosed: false,
            showCurtainTitle: false,
            curtainsTitle: "",

            showDaveSection: false,
            davePlayer: null,
            daveSubText: "",
            nameText: "",
            showDaveDrawing: false,
            daveAnswer: "",
            daveDrawing: "",

            showStageSection: false,
            stagePlayers: [],
            stageTitleText: "",
            showAnswers: false,

            showWinnerSection: false,
            //showWinnerSection: true,
            playWinnerAnim: false,
            winnerPlayers: [],
            //winnerPlayers: [
            //    {
            //        name: "BOB",
            //        avatar: 1,
            //        colourData: {
            //            score: 6,
            //        }
            //    },
            //    {
            //        name: "BOB",
            //        avatar: 6,
            //        colourData: {
            //            score: 3,
            //        }
            //    },
            //    {
            //        name: "BOB",
            //        avatar: 23,
            //        colourData: {
            //            score: 1,
            //        }
            //    }
            //],

            showPlayAgainButtons: false,
            //showPlayAgainButtons: true,
            doConfetti: false,

            gotLocationPing: true,
            connectionIssue: false,

            showStartWarning: false,
        };
        this.toggleMute = this.toggleMute.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    componentDidMount() {
        this.setTags();
        this.doReconnect();

        this.toggleMute(true, false);
        this.initAudio();
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isHost', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    initAudio() {
        this.preloadAudio();
        Howler.volume(0.5);
        this.playAudio(audio.BgMusic, true, 0.15);
    }

    preloadAudio() {
        for (let key in audio) {
            audio[key].loaded = new Howl({
                src: [audio[key].import],
                preload: true,
                loop: audio[key].loop,
                volume: audio[key].volume
            });
        }
    }

    playAudio(audioObj) {
        if (audioObj.loaded) audioObj.loaded.play();
    }

    toggleFullScreen() {
        if (fullscreenAvailable) {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                let elem = document.documentElement
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.mozExitFullscreen) {
                    document.mozExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    animatePotato(id, animation) {
        if (document.getElementById(`potato-${id}`)) {
            this.animateCSS(`#potato-${id}`, animation);
        }
    }

    animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            let node;
            if (typeof element === `string`) {
                node = document.querySelector(element);
            } else {
                node = element;
            }
            node.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, { once: true });
        });

    toggleMenu() {
        console.log("toggle menu");
        let newVal = this.state.menuOpen == true ? false : true;
        this.setState({ menuOpen: newVal });
    }

    toggleMute(force = false, value = false) {
        let newVal;
        if (force) {
            newVal = value;
        } else {
            newVal = this.state.muted == true ? false : true;
        }

        Howler.mute(newVal);
        this.setState({ muted: newVal, });
    }

    checkAndAddPlayer(player) {
        if (!this.state.players.find(elem => elem.id == player.id)) {
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
        }
    }

    removePlayer(id) {
        this.setState((prevState) => {
            return { players: prevState.players.filter(x => x.id != id), }
        });
    }

    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    signalStartGame = (confirm = false) => {
        let allConnected = true;
        for (let i = 0; i < this.state.players.length; i++) {
            let value = this.state.players[i].connected;
            if (value === false) allConnected = false;
        }

        if (!confirm && !allConnected) {
            this.setState({ showStartWarning: true });
        } else {
            this.setState({ showStartButtons: false, showStartWarning: false, });
            this.state.room.send("begin_game", { skipTutorial: this.state.tickedSkipTutorial, });
        }
    }

    goToLobby = () => {
        this.state.room.send("change_game", {});
    }

    signalNewGame = (confirm = false) => {
        let allConnected = true;
        for (let i = 0; i < this.state.players.length; i++) {
            let value = this.state.players[i].connected;
            if (value === false) allConnected = false;
        }

        if (!confirm && !allConnected) {
            this.setState({ showStartWarning: true });
        } else {
            this.setState({ showPlayAgainButtons: false, curtainsClosed: true, showStartWarning: false, });
            setTimeout(() => {
                this.state.room.send("draw_with_new_game", {});
                setTimeout(() => {
                    this.setState({ showWinnerSection: false, });
                }, 1000);
            }, 1000);
        }
    }

    closeStartWarning = () => {
        this.setState({ showStartWarning: false });
    }

    confirmNewGame = () => {
        this.signalNewGame(true);
    }

    confirmStartGame = () => {
        this.signalStartGame(true);
    }

    toggleSkipTutorial = (e) => {
        if (this.state.showStartButtons) {
            console.log("cb value: " + e.target.checked);
            this.setState({ tickedSkipTutorial: e.target.checked });
        }
    }

    animatePotato(id, animation) {
        if (document.getElementById(`potato-${id}`)) {
            this.animateCSS(`#potato-${id}`, animation);
        }
    }

    startNewRound(message) {
        this.setState({ curtainsClosed: true, curtainsTitle: `Round ${message.roundNumber}` });
        setTimeout(() => {
            this.setState({ showCurtainTitle: true, showPlayers: false, davePlayer: message.nextDave, showDaveDrawing: false, showAnswers: false, showWinnerSection: false, playWinnerAnim: false,});

            setTimeout(() => {
                this.setState({ showCurtainTitle: false, });

                setTimeout(() => {
                    this.setState({ curtainsClosed: false, showDaveSection: true, nameText: message.nextDave.name, daveSubText: ` is Dave...`, });

                    setTimeout(() => {
                        this.state.room.send("start_drawing", {});
                    }, 3000);
                }, 1000);
            }, 3000);
        }, 1500);
    }

    getStage() {
        let variationNum = 1;
        switch (this.state.stagePlayers.length) {
            case 2:
                variationNum = 1;
                break;
            case 3:
                variationNum = 2;
                break;
            case 4:
                variationNum = 3;
                break;
            case 5:
                variationNum = 4;
                break;
            case 6:
                variationNum = 5;
                break;
            case 7:
                variationNum = 6;
                break;
        }
        return <Stage variation={variationNum} players={this.state.stagePlayers} showAnswers={this.state.showAnswers} />
    }

    getArrayOfPlayers(players) {
        let arrPlayers = [];
        for (const [key, value] of Object.entries(players)) {
            if (!value.drawWithData.isDave) arrPlayers.push(value);
        };
        return arrPlayers;
    }

    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name != "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }

    getWinnerStages = () => {
        let stagePlayersOne = [];
        let stagePlayersTwo = [];

        let statePlayers = [...this.state.players];
        statePlayers.sort((a, b) => {
            return b.drawWithData.score - a.drawWithData.score;
        });

        let lastIndex = 0;
        statePlayers = statePlayers.map((x, index, arr) => {
            if (index > 0 && x.drawWithData.score < arr[index - 1].drawWithData.score) lastIndex++;
            x.index = lastIndex;
            return x;
        });

        statePlayers.forEach((x, i) => {
            if (i < 4) stagePlayersOne.push(x);
            else stagePlayersTwo.push(x);
        });

        let stages = [];
        stages.push(<div className={styles.stage}>
            <img className={styles.stageImg} src={resultsStage} />
            {
                this.getWinnerRow(stagePlayersOne)
            }
        </div>);
        if (stagePlayersTwo.length > 0) {
            stages.push(<div className={styles.stage}>
                <img className={styles.stageImg} src={resultsStage} />
                {
                    this.getWinnerRow(stagePlayersTwo)
                }
            </div>);
        }

        return stages;
    }

    getWinnerRow = (players) => {
        return <div className={styles.winnerRow}>
            {
                players.map((x) => {
                    return <div className={styles.winner}>
                        <div className={styles.easelSection}>
                            <img className={`${styles.easelImage}`} src={easelMiddle} />
                            <img className={`${styles.drawingImage}`} src={x.drawWithData.daveDrawingURL} />
                            <div className={`${styles.drawingTitle}`}>{x.drawWithData.daveDrawItem}</div>
                            {
                                [0, 1, 2].includes(x.index) &&
                                <img className={styles.badge} src={x.index == 0 ? Badge1st : x.index == 1 ? Badge2nd : Badge3rd} />
                            }
                        </div>
                        <div className={styles.potato}>
                            <Lottie
                                options={getAvatarById(x.avatar).idleAnim}
                                width="100%"
                                height="100%"
                                isClickToPauseDisabled={true}
                            />
                        </div>
                        <div className={styles.name}>{x.name}</div>
                    </div>
                })
            }
        </div>
    }


    getSortedPlayersByScore() {
        let statePlayers = [...this.state.players];
        statePlayers.sort((a, b) => {
            return b.drawWithData.score - a.drawWithData.score;
        });
        let lastIndex = 1;
        return <React.Fragment>
            {
                statePlayers.map((x, index, arr) => {
                    if (index > 0 && x.drawWithData.score < arr[index - 1].drawWithData.score) lastIndex++;
                    return <tr className={`${styles.tableRow} ${styles.player}`}>
                        <div className={styles.position}>{lastIndex}</div>
                        <div className={styles.name}>{x.name}</div>
                        <div className={styles.score}>{x.drawWithData.score}</div>
                        {/*<div className={styles.potato}>*/}
                        {/*    <Lottie*/}
                        {/*        options={getAvatarById(x.avatar).idleAnim}*/}
                        {/*        width="100%"*/}
                        {/*        height="100%"*/}
                        {/*        isClickToPauseDisabled={true}*/}
                        {/*    />*/}
                        {/*</div>*/}
                    </tr>
                })
            }
        </React.Fragment>
    }

    hideTimer() {
        this.setState({ showTimer: false, });

        if (audio.Timer5SecondsClock.loaded) audio.Timer5SecondsClock.loaded.stop();
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    startLocationChecks() {
        this.state.room.send("location_check", { gameId, });
        this.locationCheckInterval = setInterval(() => {
            if (this.state.gotLocationPing) {
                this.setState({ gotLocationPing: false, connectionIssue: false, });
            } else {
                console.log("Host Connection Issue detected");
                this.setState({ connectionIssue: true, });
                LoggingService.streamLog(this.state.logStreamId, "Host Connection Issue Detected at Draw With Dave");
                this.hostLostConnectionBug();
            }
            this.state.room.send("location_check", { gameId, });
        }, 10000);
    }

    doReconnect = () => {
        if (this.state.reconnectTries < 5) {
            const roomId = this.getQueryStringValue("roomId");
            const sessionId = this.getQueryStringValue("sessionId");
            const token = this.getQueryStringValue("token");
            console.log(roomId);
            console.log(token);

            if (this.state.connected == false) {
                this.client.reconnect(token).then(room => {
                    console.log(room.sessionId, "joined", room.name);
                    this.setState({ room: room, roomId: room.id, myId: room.sessionId, connected: true, reconnectionToken: room.reconnectionToken, });
                    this.updateToken(room.reconnectionToken);
                    room.send("update_host_token", { reconnectionToken: room.reconnectionToken });

                    room.onStateChange.once((state) => {
                        console.log("this is the first room state!", state);
                        if (state.host.id != room.sessionId) window.location = this.getRedirectURL();
                        Sentry.setUser({ id: state.host.uniqueId });

                        this.setState({ roomState: state, maxVoteCount: state.players.size, logStreamId: state.uniqueId, });
                        LoggingService.streamLog(state.uniqueId, `Host Reconnected to Draw with Dave, Reconnection Token: ${room.reconnectionToken}`);

                        if (state.drawWithData.gameState == GameStates.Loading) {
                            room.send("host_joined_game", { gameId });
                            this.startLocationChecks();
                        } else {
                            room.send("change_game", {});
                        }
                    });
                    room.onStateChange((state) => {
                        console.log(room.name, "has new state:", state);
                        this.setState({ roomState: state, maxVoteCount: state.players.size });
                    });

                    room.onMessage("location_confirmed", (message) => {
                        console.log("location_confirmed", "received on", room.name, message);
                        this.setState({ gotLocationPing: true, });
                    });

                    room.state.drawWithData.listen("gameState", (currentValue, previousValue) => {
                        if (currentValue != GameStates.Loading && currentValue != GameStates.EndGame && this.state.showStartWarning) {
                            this.setState({ showStartWarning: false });
                        }
                        this.setState({ gameState: currentValue });
                    });

                    room.state.players.onAdd((player, key) => {
                        console.log(player, "has been added at", key);
                        this.checkAndAddPlayer(player);
                        player.onChange(() => {
                        });
                        player.drawWithData.onChange(() => {
                        });
                        player.listen("connected", (value) => {
                            let statePlayers = [...this.state.players];
                            let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                            let statePlayer = { ...statePlayers[pos] };

                            statePlayer.connected = value;

                            statePlayers[pos] = statePlayer;
                            this.setState({ players: statePlayers });
                        });
                        player.listen("votedSkip", (value) => {
                            let statePlayers = [...this.state.players];
                            let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                            let statePlayer = { ...statePlayers[pos] };

                            statePlayer.votedSkip = value;

                            statePlayers[pos] = statePlayer;
                            this.setState({ players: statePlayers });
                        });
                        let changes = ["score", "currentGuess", "drawingURL", "hasSubmitted", "hasBeenDave", "earlyGuessed", "ready", "isDave", "isCorrect", "daveDrawingURL", "daveDrawItem"];
                        changes.forEach((change) => {
                            player.drawWithData.listen(change, (value) => {
                                let statePlayers = [...this.state.players];
                                let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                                let statePlayer = { ...statePlayers[pos] };
                                if (change == "score") {
                                    if (value > 0) this.playAudio(audio.PointSound);
                                }
                                statePlayer.drawWithData[change] = value;
                                statePlayers[pos] = statePlayer;
                                this.setState({ players: statePlayers });
                            });
                        });
                    });
                    room.state.players.onRemove((player, key) => {
                        console.log(player, "has been removed at", key);
                        this.removePlayer(player.id);
                    });

                    room.onMessage("animate_potato", (message) => {
                        console.log("animate_potato", "received on", room.name, message);
                        this.animatePotato(message.id, message.animation);
                    });

                    room.onMessage("begin_tutorial", (message) => {
                        console.log("begin_tutorial", "received on", room.name, message);
                        this.setState({ showTutorial: true, showStartButtons: false, showPlayers: false, });
                        this.state.room.send("show_tutorial", {});
                    });

                    room.onMessage("end_tutorial", (message) => {
                        console.log("end_tutorial", "received on", room.name, message);
                        this.setState({ showTutorial: false, showPlayers: true, });
                    });
                    room.onMessage("clicked_begin_game", (message) => {
                        this.setState({ showStartButtons: false, })
                    });
                    room.onMessage("begin_game", (message) => {
                        console.log("begin_game", "received on", room.name, message);
                        console.log("game state... ", this.state.roomState.drawWithData.gameState);
                        if ((this.state.roomState.drawWithData.gameState == GameStates.Loading || this.state.roomState.drawWithData.gameState === GameStates.Idle) && !this.state.gameBegun) {
                            this.setState({ showTutorial: false, gameBegun: true, showStartButtons: false, });
                            room.send("start_game", {});
                        }
                    });

                    room.onMessage("change_game", (message) => {
                        console.log("change_game", "received on", room.name, message);
                        this.setState({ redirect: `${this.getRedirectURL()}${this.state.roomState.isRoku ? "/roku" : "/lobby"}/?token=${this.state.reconnectionToken}` });
                        this.state.room.leave(false);
                    });

                    room.onMessage("start_round", (message) => {
                        console.log("start_round", "received on", room.name, message);
                        this.startNewRound(message);
                    });

                    room.onMessage("start_drawing", (message) => {
                        console.log("start_drawing", "received on", room.name, message);
                        this.setState({ daveSubText: "Draw along with Dave!", nameText: "", });
                    });

                    room.onMessage("start_early_guess", (message) => {
                        console.log("start_early_guess", "received on", room.name, message);
                        this.setState({ curtainsClosed: true, curtainsTitle: "Time's up! What did Dave draw?", });
                        setTimeout(() => {
                            this.setState({ showCurtainTitle: true, });
                            setTimeout(() => {
                                this.state.room.send("start_early_guess_timer", {});
                            }, 1000);
                        }, 1000);
                    });

                    room.onMessage("show_all_drawings", (message) => {
                        console.log("show_all_drawings", "received on", room.name, message);
                        this.setState({ stagePlayers: this.getArrayOfPlayers(message.players), });

                        setTimeout(() => {
                            this.setState({ showCurtainTitle: false, });

                            setTimeout(() => {
                                this.setState({ curtainsTitle: "How did everyone do?", showCurtainTitle: true, });

                                setTimeout(() => {
                                    this.setState({ showCurtainTitle: false, showStageSection: true, });

                                    setTimeout(() => {
                                        this.setState({ stageTitleText: "Make your final guess now!", });
                                        this.state.room.send("start_final_guess", {});
                                    }, 3000);
                                }, 2500);
                            }, 1000);
                        }, 1000);
                    });
                    room.onMessage("show_all_guesses", (message) => {
                        console.log("show_all_guesses", "received on", room.name, message);
                        let stagePlayers = [...this.state.stagePlayers];
                        stagePlayers.forEach((x, index) => {
                            x.drawWithData.currentGuess = this.getArrayOfPlayers(message.players)[index].drawWithData.currentGuess;
                        });
                        this.setState({ stagePlayers, });
                        this.setState({ stageTitleText: "Time for the guesses!", });

                        setTimeout(() => {
                            this.setState({ showAnswers: true, });

                            setTimeout(() => {
                                this.setState({ stageTitleText: "Dave, pick the correct answers!", });
                                this.state.room.send("start_choosing", {});
                            }, 2000);
                        }, 1500);
                    });

                    room.onMessage("show_choices", (message) => {
                        console.log("show_choices", "received on", room.name, message);
                        this.setState({ stageTitleText: "How'd they do, Dave?", });
                        setTimeout(() => {
                            let index = 0;
                            const arrPlayers = this.getArrayOfPlayers(message.players);
                            const validationInterval = setInterval(() => {
                                let stagePlayers = [...this.state.stagePlayers];
                                stagePlayers[index].drawWithData.isCorrect = arrPlayers[index].drawWithData.isCorrect;
                                stagePlayers[index].drawWithData.showValidation = true;

                                if (stagePlayers[index].drawWithData.isCorrect) this.playAudio(audio.CorrectGuess);
                                else this.playAudio(audio.WrongGuess);

                                this.setState({ stagePlayers, });
                                index++;

                                if (index >= stagePlayers.length) {
                                    setTimeout(() => {
                                        this.state.room.send("finalise_round", {});
                                    }, 2000);
                                    clearInterval(validationInterval);
                                }
                            }, 800);
                        }, 1500);
                    });

                    room.onMessage("continue_round", (message) => {
                        console.log("continue_round", "received on", room.name, message);
                        this.setState({
                            showDaveSection: true,
                            showDaveDrawing: true,
                            daveDrawing: message.daveDrawingURL,
                            daveAnswer: message.question,
                            showStageSection: false,
                            daveSubText: "",
                            nameText: "",
                        });
                        setTimeout(() => {
                            this.setState({ showCurtainTitle: true, curtainsTitle: "What did Dave draw?", stageTitleText: "", });

                            setTimeout(() => {
                                this.setState({ showCurtainTitle: false, });

                                setTimeout(() => {
                                    this.setState({ curtainsClosed: false, });
                                    this.playAudio(audio.DaveImageReveal);

                                    setTimeout(() => {
                                        this.setState({ showPlayers: true, });
                                        setTimeout(() => {
                                            this.state.room.send("award_scores", {});
                                        }, 1000);

                                        setTimeout(() => {
                                            this.state.room.send("new_round", {});
                                        }, 5000);
                                    }, 3000);
                                }, 1000);
                            }, 2000);
                        }, 2000);
                    });

                    room.onMessage("start_final_guess", (message) => {
                        console.log("start_final_guess", "received on", room.name, message);
                        this.state.room.send("start_final_guess_timer", {});
                    });

                    room.onMessage("game_over", (message) => {
                        console.log("game_over", "received on", room.name, message);
                        this.setState({ showPlayers: false, curtainsClosed: true, winnerPlayers: message.winners, });
                        this.hideTimer();
                        setTimeout(() => {
                            this.setState({ showCurtainTitle: true, curtainsTitle: "The Winners Are..." });
                            this.playAudio(audio.DrumRoll);

                            setTimeout(() => {
                                this.setState({ showCurtainTitle: false, showDaveSection: false, });
                                this.playAudio(audio.Ending);
                                setTimeout(() => {
                                    this.state.room.send("reached_end", {});
                                    this.setState({ showWinnerSection: true, curtainsClosed: false, showPlayAgainButtons: true, doConfetti: true, });
                                    setTimeout(() => {
                                        this.setState({ playWinnerAnim: true, });
                                    }, 1500);
                                }, 500);
                            }, 2000);
                        }, 1000);
                    });

                    room.onMessage("new_game", (message) => {
                        console.log("new_game", "received on", room.name, message);
                        this.setState({ showPlayAgainButtons: false, curtainsClosed: true, });
                        this.setState({ showPlayAgainButtons: false, curtainsClosed: true, });
                        setTimeout(() => {
                            this.setState({ showWinnerSection: false, });
                            this.state.room.send("new_round", {});
                        }, 1000);
                    });

                    room.onMessage("update_timer", (message) => {
                        console.log("update_timer", "received on", room.name, message);

                        this.setState({ showTimer: true, });
                        if (message.count <= 5) {
                            if (message.count == 5) {
                                //this.playAudio(audio.Timer5SecondsStart);
                                this.playAudio(audio.Timer5SecondsClock);
                            }
                            if (message.count <= 0) {
                                this.hideTimer();
                            }
                            this.setState({ timerOptions: timerEnd });
                        } else {
                            this.setState({ timerOptions: timerTurning });
                        }
                        this.setState({ timerText: message.count, });
                    });

                    room.onMessage("stop_timer", (message) => {
                        this.hideTimer();
                    });

                    room.onError((code, message) => {
                        console.log(this.client.id, "couldn't join", room.name);
                        LoggingService.streamLog(this.state.logStreamId, `Host OnError at Draw With Dave, code: ${code} Message: ${JSON.stringify(message)}`);
                        //LoggingService.logError(message, code);
                    });
                    room.onLeave((code) => {
                        console.log(this.client.id, "left", room.name);
                        LoggingService.streamLog(this.state.logStreamId, `Host Left Draw With Dave, Code: ${code}`);
                        if (!this.state.redirect) {
                            this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                            setTimeout(() => {
                                this.doReconnect();
                            }, 1000);
                        }
                    });
                }).catch(e => {
                    console.log("JOIN ERROR", e);
                    this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                    const message = e.message ? e.message : "An error occured Hosting Draw with Dave.";
                    if (this.state.logStreamId.length > 0) LoggingService.streamLog(this.state.logStreamId, `Host OnJoinError at Draw With Dave: ${JSON.stringify(e)}`);
                    //LoggingService.logError(message, e);
                    setTimeout(() => {
                        this.doReconnect();
                    }, 1000);
                });
            }
        } else {
            this.setState({ redirect: `${this.getRedirectURL()}${this.state.roomState.isRoku ? "/roku" : "/lobby"}` })
            if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
        }
    }

    getAnimationStyle() {
        switch (this.state.players.length) {
            case 2:
                return styles.animate2;
            case 3:
                return styles.animate3; 
            case 4:
                return styles.animate4;
            case 5:
                return styles.animate5;
            case 6:
                return styles.animate6;
            case 7:
                return styles.animate7; 
            case 8:
                return styles.animate8;
        }
    }

    render() {

        if (this.state.redirect) {
            return (
                <React.Fragment>
                    <div id="gameContainer" className={styles.gameContainer}>
                        <div className={styles.loadingContainer}>
                            <Loading loadingText={"Sending you to the lobby!"} />
                        </div>
                    </div>
                    <div style={{ opacity: 0 }}>
                        <Route path="/" render={() => (window.location = this.state.redirect)} />
                    </div>'
                </React.Fragment>
            )
        }
        return (
            <div>
                <audio ref />
                <div id="gameContainer" className={styles.gameContainer}>
                    {
                        !this.state.connected ?
                            <div className={styles.logoSection}>
                                <img src={logo} className={styles.logo} />
                            </div>
                            :
                            <React.Fragment>
                                {/*<div className={styles.testRow}>*/}
                                {/*    <img className={styles.testItem} src={CBTitleGif} />*/}
                                {/*    <div className={`${styles.testItem}`} >*/}
                                {/*        <Lottie*/}
                                {/*            options={CBTitleAnim}*/}
                                {/*            width="100%"*/}
                                {/*            height="100%"*/}
                                {/*            isClickToPauseDisabled={true}*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*    <div className={`${styles.testItem}`} >*/}
                                {/*        <Lottie*/}
                                {/*            options={Round5Anim}*/}
                                {/*            width="100%"*/}
                                {/*            height="100%"*/}
                                {/*            isClickToPauseDisabled={true}*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <Menu room={this.state.room} toggleMute={this.toggleMute} toggleMenu={this.toggleMenu} menuOpen={this.state.menuOpen} muted={this.state.muted} />
                                {
                                    this.state.showTutorial &&
                                    <Tutorial room={this.state.room} players={this.state.players} />
                                }
                                {
                                    this.state.showStartWarning && [GameStates.Loading, GameStates.EndGame].includes(this.state.gameState) &&
                                    <ErrorModal
                                        title={"Are you ready to play?"}
                                        styles={"d-flex"}
                                        message={"It looks like all the players might not be connected to the game, are you sure you would like to start?"}
                                        callback={this.closeStartWarning}
                                        callbackText={"No"}
                                        callback2={this.state.showStartButtons ? this.confirmStartGame : this.confirmNewGame}
                                        callbackText2={"Yes"}
                                    />
                                }
                                {/*<div style={{ zIndex: 20, }}>*/}
                                {/*    {*/}
                                {/*        this.state.doConfetti &&*/}
                                {/*        <Confetti*/}
                                {/*            width={window.innerWidth}*/}
                                {/*            height={window.innerHeight}*/}
                                {/*            initialVelocityY={20}*/}
                                {/*            numberOfPieces={500}*/}
                                {/*            recycle={false}*/}
                                {/*            confettiSource={{ x: window.innerWidth / 2, y: window.innerHeight + 10, width: window.innerWidth + 10, height: 0 }}*/}
                                {/*            initialVelocityY={{ min: -10, max: -30, }}*/}
                                {/*            initialVelocityX={{ min: -10, max: 10, }}*/}
                                {/*            onConfettiComplete={() => this.setState({ doConfetti: false, })}*/}
                                {/*        />*/}
                                {/*    }*/}
                                {/*</div>*/}
                                <div className={styles.roomCode}>
                                    <div className={styles.textBox}>
                                        <div className={styles.text}>{this.getRedirectURL(true)}</div>
                                        <div className={styles.text}>Code: <span className={`${styles.text} ${styles.code}`}>{this.state.roomId}</span></div>
                                        <div className={styles.iconsBox}>
                                            <div className={styles.muteToggle} onClick={() => this.toggleMute()}>
                                                <img src={this.state.muted ? mutedIcon : unmutedIcon} className={styles.muteIcon} />
                                            </div>
                                            {
                                                fullscreenAvailable ?
                                                    <div className={styles.muteToggle} onClick={() => { this.toggleFullScreen() }}>
                                                        <img src={fullscreenIcon} className={styles.muteIcon} />
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className={styles.muteToggle} onClick={() => this.toggleMenu()}>
                                                <img src={helpIcon} className={styles.muteIcon} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.qrCodeBox}>
                                        <QRCode
                                            className={styles.qrCode}
                                            value={`${this.getRedirectURL()}/play/?qrCode=${this.state.roomId}`}
                                            bgColor="rgba(0,0,0,0)"
                                        />
                                    </div>
                                </div>
                                <div className={`${styles.timerSection} ${this.state.showTimer && styles.show}`}>
                                    <div className={styles.timerText}>{this.state.timerText}</div>
                                    <div className={styles.clock}>
                                        <Lottie
                                            options={this.state.timerOptions}
                                            width="100%"
                                            height="100%"
                                            isClickToPauseDisabled={true} />
                                    </div>
                                </div>
                                <div className={`${styles.logoSection} ${this.state.connected ? styles.topLeft : ""}`}>
                                    <img src={logo} className={`${styles.logo}`} />
                                </div>
                                {
                                    this.state.showStartButtons &&
                                    <div className={styles.startButtonSection}>
                                        <button className={`${styles.mainButton} ${styles.larger}`} onClick={() => this.signalStartGame()}>
                                            <img className={styles.buttonImg} src={ButtonImg} />
                                            Start Game
                                        </button>
                                        <button className={`${styles.mainButton}`} onClick={this.goToLobby}>
                                            <img className={styles.buttonImg} src={ButtonImg} />
                                            Go To Lobby
                                        </button>
                                        <div className={styles.skipBox}>
                                            <input className={styles.checkbox} type="checkbox" id="checkbox" name="checkbox" onChange={this.toggleSkipTutorial} />
                                            <label for="checkbox">Skip Tutorial</label>
                                        </div>
                                    </div>
                                }
                                <div className={`${styles.playerColumn} ${styles.left} ${this.state.showPlayers && styles.show}`}>
                                    {
                                        this.state.players.map((x, index) => {
                                            if (index % 2 == 0) {
                                                return <Player player={x} left={true} davePlayer={this.state.davePlayer} />
                                            }
                                        })
                                    }
                                </div>
                                <div className={`${styles.playerColumn} ${styles.right} ${this.state.showPlayers && styles.show}`}>
                                    {
                                        this.state.players.map((x, index) => {
                                            if (index % 2 == 1) {
                                                return <Player player={x} davePlayer={this.state.davePlayer} />
                                            }
                                        })
                                    }
                                </div>
                                <div className={`${styles.curtains}`}>
                                    <img src={curtainImg} className={`${styles.curtain} ${styles.left} ${this.state.curtainsClosed && styles.closed}`} />
                                    <img src={curtainImg} className={`${styles.curtain} ${styles.right} ${(this.state.curtainsClosed || this.state.showWinnerSection) && styles.closed}`} />
                                    <div className={`${styles.titleText} ${this.state.showCurtainTitle && styles.show}`}>{this.state.curtainsTitle}</div>
                                </div>
                                <div className={`${styles.daveSection} ${this.state.showDaveSection && styles.show}`}>
                                    <div className={`${styles.easelSection} ${this.state.showDaveDrawing && styles.front}`}>
                                        {
                                            this.state.showDaveDrawing &&
                                            <React.Fragment>
                                                <div className={styles.daveAnswer} style={{ fontSize: this.state.daveAnswer.length > 10 ? this.state.daveAnswer.length > 20 ? "3vh" : "4vh" : "5vh" }}>{this.state.daveAnswer}</div>
                                                <img className={styles.daveDrawing} src={this.state.daveDrawing} />
                                            </React.Fragment>
                                        }
                                        <img src={this.state.showDaveDrawing ? easelFront : easelBack} className={`${styles.daveEasel}`} />
                                    </div>
                                    <div className={`${styles.davePotato}`} >
                                        {
                                            this.state.davePlayer &&
                                            <Lottie
                                                options={getAvatarById(this.state.davePlayer.avatar).dave}
                                                width="100%"
                                                height="100%"
                                                isClickToPauseDisabled={true}
                                            />
                                        }
                                    </div>
                                    <img src={stool} className={styles.daveStool} />
                                    <div className={styles.daveSubText}><span className={styles.smaller}>{this.state.nameText} </span>{this.state.daveSubText}</div>
                                </div>
                                <div className={`${styles.stageSection} ${this.state.showStageSection && styles.show}`}>
                                    {
                                        this.getStage()
                                    }
                                    <div className={styles.stageTitle}>{this.state.stageTitleText}</div>
                                </div>
                                <div className={`${styles.winnerSection} ${this.state.showWinnerSection && styles.show}`}>
                                    <div className={`${styles.stageHalf} ${this.state.playWinnerAnim ? this.getAnimationStyle() : ""}`}>
                                        {
                                            this.getWinnerStages()
                                        }
                                    </div>
                                </div>
                                <div className={`${styles.leaderboardSection} ${this.state.showPlayAgainButtons && styles.show}`}>
                                    <div className={`${styles.tableRow} ${styles.header}`}>
                                        <div className={styles.position}>position</div>
                                        <div className={styles.name}>player</div>
                                        <div className={styles.score}>score</div>
                                    </div>
                                    {
                                        this.getSortedPlayersByScore()
                                    }
                                </div>
                                {
                                    this.state.showPlayAgainButtons &&
                                    <div className={styles.playAgainButtonSection}>
                                            <button className={`${styles.button}`} onClick={() => this.signalNewGame()}>
                                                <img className={styles.buttonImg} src={ButtonImg} />
                                                Play Again
                                            </button>
                                            <button className={`${styles.button}`} onClick={this.goToLobby}>
                                                <img className={`${styles.buttonImg} ${styles.flipped}`} src={ButtonImg} />
                                                Return to lobby
                                            </button>
                                    </div>
                                }
                            </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}