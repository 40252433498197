import React, { Component } from 'react';

import helpIcon from "images/Host/scrawl_help.png";
import styles from 'components/ClientMenuIconStyles.module.scss';
import Menu from 'components/Menu';

export default class ClientMenuIcon extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu = () => {
        this.setState({ open: !this.state.open });
    }

    render() {
        return (
            <div className={`${styles.clientMenuContainer}`}>
                <img src={helpIcon} alt="Help" className={`${styles.clientMenuIcon}`} onClick={this.toggleMenu} />
                <Menu room={this.props.room} toggleMenu={this.toggleMenu} menuOpen={this.state.open} />
             </div>
        )
    }

}