import React, { Component } from 'react';

import styles from 'components/MenuStyles.module.scss';

export default class Menu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    toggleMenu = () => {
        this.setState({ open: !this.state.open });
    }

    goToLobby = () => {
        this.props.room.send("change_game", { });
    }

    render() {
        return (
            <div className={`${styles.menuContainer} ${this.props.menuOpen ? styles.open : ""} `}>
                <div className={`${styles.menuSection} ${this.props.menuOpen ? styles.open : ""}`}>
                    {/*<div className={`${styles.toggle}`} onClick={this.toggleMenu}>{this.state.open ? "Close" : "Open"} Menu</div>*/}
                    <div className={styles.spacer} />
                    {
                        this.props.toggleMute &&
                        <>
                            <div className={styles.button} onClick={() => this.props.toggleMute()}>{this.props.muted ? "Unmute" : "Mute"}</div>
                            <div className={styles.spacer} />
                        </>
                    }
                    <div className={styles.button} onClick={this.goToLobby}>Go To Lobby</div>
                    <div className={styles.spacer} />
                    <div className={styles.button} onClick={() => this.props.toggleMenu()}>Close</div>
                    <div className={styles.spacer} />
                </div>
             </div>
        )
    }

}